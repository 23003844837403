import {API} from 'aws-amplify';
import jsonp from 'jsonp';
import config from '../../config';
import qs from 'qs';
import moment from 'moment';

const USER_API_NAME = 'userApi';
const USER_API_UN_AUTH_NAME = 'userApiNoAuth';
const USER_SETTINGS_API_NAME = 'userSettingsApi';
const USER_SETTINGS_API_UN_AUTH_NAME = 'userSettingsApiNoAuth';
const API_ORGANISATIONS_NAME = 'organisationsApi';
const API_QUESTIONS_NAME = 'questionsApi';
const API_STATISTICS_NAME = 'statisticsApi';
const API_OCCUPATIONS_NAME = 'occupationsApi';
const API_INSIGHTS_NAME = 'insightsApi';
const API_FUN_FACTS_NAME = 'funFactsApi';
const API_RECOGNITIONS_NAME = 'recognitionsApi';
const API_NOTES_NAME = 'notesApi';
const API_INFO_NAME = 'infoApi';
const API_RESOURCES_NAME = 'resourcesApi';
const API_TBUCKS_NAME = 'tbucksApi';
const API_PERFORMANCE_NAME = 'performanceApi';
const API_BUDDY_NAME = "buddyApi";
const API_SPEAKUP_USERS_NAME = "speakupUsersApi";

const DATE_FORMAT = 'YYYY-MM-DD';

export default class Api {

    static sendContactMail(firstName, emailAddress) {
        const body = {
            first_name: firstName,
            email_address: emailAddress
        };

        return API.post(USER_API_UN_AUTH_NAME, '/contact/request', {body});
    }

    static subscribeUser(email) {
        const queryString = qs.stringify({
            u: config.mailchimp.unique_id,
            id: config.mailchimp.audience_id,
            EMAIL: email
        });
        return new Promise((resolve, reject) => {
            jsonp(`https://tcup.us4.list-manage.com/subscribe/post-json?${queryString}`, {param: 'c'}, (err, data) => {
                if (err) return reject(err.msg);
                if (data.result === 'error') {
                    return reject(data.msg);
                }
                return resolve(data.msg);
            });
        });
    }

    static createUser(user) {
        const newDbUser = {
            email_address: user.email.toLowerCase(),
            password: user.password
        };
        return API.post(USER_API_UN_AUTH_NAME, '/user', {body: newDbUser});
    }

    static createOrganisationUser(user) {
        const newDbUser = {
            email_address: user.email.toLowerCase(),
            user_type: user.user_type
        };
        return API.post(USER_API_NAME, '/organisation/user', {body: newDbUser});
    }

    static attachEndUserToOrganisation(email) {
        const body = {
            email_address: email
        };

        return API.post(USER_API_NAME, '/organisation/user/attach', {body: body});
    }

    static detachUserFromOrganisation(userId) {
        const detachedUserId = {
            userId
        };

        return API.post(USER_API_NAME, '/organisation/user/detach', {body: detachedUserId});
    }

    static importUsers(importName, fileKey) {
        const body = {
            name: importName,
            key: `public/${fileKey}`
        };

        return API.post(USER_API_NAME, `/users/import`, {body: body});
    }

    static getEndUserInCorporation(props) {
        const config = {
            queryStringParameters: {
                page: props.page,
                direction: props.direction,
                sortBy: props.sortParams
            }
        };

        if (props.emailFilter !== undefined && props.emailFilter !== null) {
            config.queryStringParameters.emailAddress = props.emailFilter
        }

        if (props.teamId !== undefined && props.teamId !== null) {
            config.queryStringParameters.teamId = props.teamId
        }

        if (props.locationId !== undefined && props.locationId !== null) {
            config.queryStringParameters.locationId = props.locationId
        }

        return API.get(USER_API_NAME, '/organisation/users', config);
    }

    static exportUsers(props) {
        const config = {
            queryStringParameters: {
                page: props.page,
                direction: props.direction,
                sortBy: props.sortParams,
                userType: props.userType
            }
        };

        if (props.organisationId !== undefined && props.organisationId !== null) {
            config.queryStringParameters.organisationId = props.organisationId
        }

        if (props.emailFilter !== undefined && props.emailFilter !== null) {
            config.queryStringParameters.emailAddress = props.emailFilter
        }

        if (props.teamId !== undefined && props.teamId !== null) {
            config.queryStringParameters.teamId = props.teamId
        }

        if (props.locationId !== undefined && props.locationId !== null) {
            config.queryStringParameters.locationId = props.locationId
        }

        return API.get(USER_API_NAME, '/organisation/users/export', config);
    }

    static createInternalAdmin(user) {
        return API.post(USER_API_NAME, '/user/admin', {body: user});
    }

    static getInternalAdmins(props) {
        const config = {
            queryStringParameters: {
                'page': props.page,
                'direction': props.direction,
                'sortBy': props.sortParams,
                'emailAddress': props.emailFilter
            }
        };

        return API.get(USER_API_NAME, '/user/admin', config);
    }

    static deleteInternalAdmin(id) {
        return API.del(USER_API_NAME, `/user/admin/${id}`);
    }

    static createOrganisationAdmin(user) {
        return API.post(USER_API_NAME, '/organisation/admin', {body: user});
    }

    static leaveOrganisation() {
        return API.put(USER_API_NAME, '/organisation/user/leave', {});
    }

    static getOrganisationAdmins(props) {
        const config = {
            queryStringParameters: {
                page: props.page,
                direction: props.direction,
                sortBy: props.sortParams,
                emailAddress: props.emailFilter,
                organisationId: props.organisationId
            }
        };

        return API.get(USER_API_NAME, '/organisation/admins', config);
    }

    static deleteOrganisationAdmin(id) {
        return API.del(USER_API_NAME, `/organisation/admin/${id}`);
    }

    static getTrackingData(props) {
        const config = {
            queryStringParameters: {
                dateFrom: props.viewFrom.format(DATE_FORMAT),
                dateTo: props.viewTo.format(DATE_FORMAT)
            }
        };

        if (props.organisationId) {
            config.queryStringParameters.organisationId = props.organisationId
        }

        if (props.occupationId) {
            config.queryStringParameters.occupationId = props.occupationId
        }

        if (props.teamId) {
            config.queryStringParameters.teamId = props.teamId
        }

        if (props.locationId) {
            config.queryStringParameters.locationId = props.locationId
        }

        if (props.genderId) {
            config.queryStringParameters.genderId = props.genderId
        }

        if (props.age) {
            config.queryStringParameters.age = props.age
        }

        if (props.fitnessLevelId) {
            config.queryStringParameters.fitnessLevelId = props.fitnessLevelId
        }

        if (props.source) {
            config.queryStringParameters.source = props.source
        }

        return API.get(API_STATISTICS_NAME, `/audit/usage`, config);
    }

    static getTrackingDataExport(props) {
        const config = {
            queryStringParameters: {
                dateFrom: props.viewFrom.format(DATE_FORMAT),
                dateTo: props.viewTo.format(DATE_FORMAT)
            }
        };

        if (props.organisationId) {
            config.queryStringParameters.organisationId = props.organisationId
        }

        if (props.occupationId) {
            config.queryStringParameters.occupationId = props.occupationId
        }

        if (props.teamId) {
            config.queryStringParameters.teamId = props.teamId
        }

        if (props.locationId) {
            config.queryStringParameters.locationId = props.locationId
        }

        if (props.genderId) {
            config.queryStringParameters.genderId = props.genderId
        }

        if (props.age) {
            config.queryStringParameters.age = props.age
        }

        if (props.fitnessLevelId) {
            config.queryStringParameters.fitnessLevelId = props.fitnessLevelId
        }

        if (props.source) {
            config.queryStringParameters.source = props.source
        }

        return API.get(API_STATISTICS_NAME, `/audit/usage/export`, config);
    }

    static updateUser(payload, cognitoId) {
        return API.put(USER_API_NAME, `/users/${cognitoId}`, {body: payload});
    }

    static getCurrentUser() {
        return API.get(USER_API_NAME, `/v2/user`);
    }

    static deleteUser() {
        return API.del(USER_API_NAME, '/user');
    }

    static wasUserDetached(userId) {
        const body = {
            userId
        };

        return API.get(USER_SETTINGS_API_NAME, '/user/detached', body);
    }

    static updateUserSettings(userSettings) {
        return API.put(USER_SETTINGS_API_NAME, `/user/settings`, {body: userSettings});
    }

    static isUserFirstLogin() {
        return API.get(USER_SETTINGS_API_NAME, '/user/first-login');
    }

    static getAllFitnessLevel() {
        return API.get(USER_SETTINGS_API_NAME, '/fitness-level/all');
    }

    static getAllGender() {
        return API.get(USER_SETTINGS_API_NAME, '/gender/all');
    }

    static updateUserEmail() {
        return API.put(USER_SETTINGS_API_NAME, '/user/email');
    }

    static getUsersFeatures() {
        return API.get(USER_SETTINGS_API_NAME, `/user/features`);
    }

    static recoverAccount(emailAddress) {
        const email_address = {
            email_address: emailAddress,
        };

        return API.post(USER_SETTINGS_API_UN_AUTH_NAME, '/user/recover', {body: email_address});
    }

    static createOrganisation(props) {
        const config = {
            body: {
                name: props.name,
                domains: props.domains,
                teams: props.teams,
                locations: props.locations,
                organisation_type_id: props.organisationTypeId,
                organisation_size_id: props.organisationSizeId,
                enforce_domain_check: props.enforceDomainsCheck,
                withu_access: props.withuAccessCheck
            }
        };

        return API.post(API_ORGANISATIONS_NAME, '/organisation', config);
    }

    static getOrganisations(props) {
        const config = {
            queryStringParameters: {
                sortBy: props.sortBy,
                sortOrder: props.sortDirection,
                page: props.page
            }
        };

        if (props.filterByType) {
            config.queryStringParameters.group = props.filterByType
        }

        if (props.filterByNameParams) {
            config.queryStringParameters.name = props.filterByNameParams
        }

        return API.get(API_ORGANISATIONS_NAME, '/organisation', config);
    }

    static getOrganisation(id) {
        return API.get(API_ORGANISATIONS_NAME, `/organisation/${id}`);
    }

    static getAllOrganisations() {
        return API.get(API_ORGANISATIONS_NAME, `/organisation/all`);
    }

    static deleteOrganisation(id) {
        return API.del(API_ORGANISATIONS_NAME, `/organisation/${id}`);
    }

    static updateOrganisation(props) {
        const config = {
            body: {
                name: props.name,
                domains: props.domains,
                teams: props.teams,
                locations: props.locations,
                organisation_type_id: props.organisationTypeId,
                organisation_size_id: props.organisationSizeId,
                enforce_domain_check: props.enforceDomainsCheck,
                withu_access: props.withuAccessCheck
            }
        };

        return API.patch(API_ORGANISATIONS_NAME, `/organisation/${props.id}`, config);
    }

    static getOrganisationFeatures() {
        return API.get(API_ORGANISATIONS_NAME, `/organisation/features`);
    }

    static getOrganisationFeaturesForInternalAdmin(id) {
        return API.get(API_ORGANISATIONS_NAME, `/organisation/features/${id}`);
    }

    static createCheckupAccess(payload) {
        return API.post(API_ORGANISATIONS_NAME, `/organisation-access`, {body: payload});
    }

    static getQuestions(props) {
        const config = {
            queryStringParameters: {
                sortOrder: props.sortOrder,
                page: props.page
            }
        };

        if (props.type !== undefined && props.type !== null) {
            config.queryStringParameters.type = props.type
        }

        if (props.stateFilter !== undefined && props.stateFilter !== null) {
            config.queryStringParameters.active = props.stateFilter
        }

        if (props.validFilter !== undefined && props.validFilter !== null) {
            config.queryStringParameters.valid = props.validFilter
        }

        if (props.categoryFilter !== undefined && props.categoryFilter !== null) {
            config.queryStringParameters.category = props.categoryFilter
        }

        if (props.subcategoryFilter !== undefined && props.subcategoryFilter !== null) {
            config.queryStringParameters.subcategory = props.subcategoryFilter
        }

        if (props.organisationsFilter !== undefined && props.organisationsFilter !== null) {
            config.queryStringParameters.organisationId = props.organisationsFilter
        }

        return API.get(API_QUESTIONS_NAME, `/question/all`, config);
    }

    static listQuestions(props) {
        const config = {
            queryStringParameters: {
                questionType: props.questionType
            }
        };

        if (props.subcategoryId) {
            config.queryStringParameters.subcategory = props.subcategoryId;
        }

        if (props.organisationSizeId) {
            config.queryStringParameters.organisationSize = props.organisationSizeId;
        }

        if (props.organisationTypeId) {
            config.queryStringParameters.organisationType = props.organisationTypeId;
        }

        if (props.categoryId) {
            config.queryStringParameters.category = props.categoryId;
        }

        return API.get(API_QUESTIONS_NAME, `/question/list`, config);
    }

    static getQuestion(id) {
        return API.get(API_QUESTIONS_NAME, `/question/${id}`);
    }

    static addQuestion(props) {
        return API.post(API_QUESTIONS_NAME, `/question`, {body: props});
    }

    static updateQuestion(props) {
        return API.patch(API_QUESTIONS_NAME, `/question/${props.id}`, {body: props});
    }

    static copyQuestion(props) {
        return API.patch(API_QUESTIONS_NAME, `/question/copy/${props.id}`, {body: props});
    }

    static deleteQuestion(id) {
        return API.del(API_QUESTIONS_NAME, `/question/${id}`);
    }

    static getStatistics(props) {
        const config = {
            queryStringParameters: {
                viewFrom: props.date[0].format(DATE_FORMAT),
                viewTo: props.date[1].format(DATE_FORMAT),
                viewType: props.viewType,
            }
        };

        if (props.statsType) {
            config.queryStringParameters.statsType = props.statsType
        }

        if (props.categoryId) {
            config.queryStringParameters.categoryId = props.categoryId
        }

        return API.get(API_STATISTICS_NAME, '/statistics', config);
    }

    static getCorpStatistics(props) {
        const config = {
            queryStringParameters: {
                viewFrom: props.viewFrom.format(DATE_FORMAT),
                viewTo: props.viewTo.format(DATE_FORMAT),
                viewType: props.viewType,
            }
        };

        if (props.statsType) {
            config.queryStringParameters.statsType = props.statsType
        }

        if (props.categoryId) {
            config.queryStringParameters.categoryId = props.categoryId
        }

        if (props.occupationId) {
            config.queryStringParameters.occupationId = props.occupationId
        }

        if (props.teamId) {
            config.queryStringParameters.teamId = props.teamId
        }

        if (props.locationId) {
            config.queryStringParameters.locationId = props.locationId
        }

        if (props.genderId) {
            config.queryStringParameters.genderId = props.genderId
        }

        if (props.ageGroup) {
            config.queryStringParameters.ageGroup = props.ageGroup
        }

        if (props.fitnessLevelId) {
            config.queryStringParameters.fitnessLevelId = props.fitnessLevelId
        }

        if (props.source) {
            config.queryStringParameters.source = props.source
        }

        return API.get(API_STATISTICS_NAME, '/statistics/corp', config);
    }

    static getInternalStatistics(props) {
        const config = {
            queryStringParameters: {
                viewFrom: props.viewFrom.format(DATE_FORMAT),
                viewTo: props.viewTo.format(DATE_FORMAT),
                organisationId: props.organisationId,
                viewType: props.viewType
            }
        };

        if (props.statsType) {
            config.queryStringParameters.statsType = props.statsType
        }

        if (props.categoryId) {
            config.queryStringParameters.categoryId = props.categoryId
        }

        if (props.occupationId) {
            config.queryStringParameters.occupationId = props.occupationId
        }

        if (props.teamId) {
            config.queryStringParameters.teamId = props.teamId
        }

        if (props.locationId) {
            config.queryStringParameters.locationId = props.locationId
        }

        if (props.genderId) {
            config.queryStringParameters.genderId = props.genderId
        }

        if (props.ageGroup) {
            config.queryStringParameters.ageGroup = props.ageGroup
        }

        if (props.fitnessLevelId) {
            config.queryStringParameters.fitnessLevelId = props.fitnessLevelId
        }

        if (props.source) {
            config.queryStringParameters.source = props.source
        }

        return API.get(API_STATISTICS_NAME, '/statistics/internal', config);
    }

    static getProgress(props) {
        const config = {
            queryStringParameters: {
                viewFrom: props.date[0].format(DATE_FORMAT),
                viewTo: props.date[1].format(DATE_FORMAT),
            }
        };

        if (props.categoryId) {
            config.queryStringParameters.categoryId = props.categoryId
        }

        return API.get(API_STATISTICS_NAME, '/progress', config);
    }

    static getCorpProgress(props) {
        const config = {
            queryStringParameters: {
                viewFrom: props.viewFrom.format(DATE_FORMAT),
                viewTo: props.viewTo.format(DATE_FORMAT),
            }
        };

        if (props.organisationId) {
            config.queryStringParameters.organisationId = props.organisationId
        }

        if (props.categoryId) {
            config.queryStringParameters.categoryId = props.categoryId
        }

        if (props.occupationId) {
            config.queryStringParameters.occupationId = props.occupationId
        }

        if (props.teamId) {
            config.queryStringParameters.teamId = props.teamId
        }

        if (props.locationId) {
            config.queryStringParameters.locationId = props.locationId
        }

        if (props.genderId) {
            config.queryStringParameters.genderId = props.genderId
        }

        if (props.ageGroup) {
            config.queryStringParameters.ageGroup = props.ageGroup
        }

        if (props.fitnessLevelId) {
            config.queryStringParameters.fitnessLevelId = props.fitnessLevelId
        }

        return API.get(API_STATISTICS_NAME, '/progress/corp', config);
    }

    static getInternalProgress(props) {
        const config = {
            queryStringParameters: {
                viewFrom: props.viewFrom.format(DATE_FORMAT),
                viewTo: props.viewTo.format(DATE_FORMAT),
            }
        };

        if (props.categoryId) {
            config.queryStringParameters.categoryId = props.categoryId
        }

        if (props.occupationId) {
            config.queryStringParameters.occupationId = props.occupationId
        }

        if (props.genderId) {
            config.queryStringParameters.genderId = props.genderId
        }

        if (props.ageGroup) {
            config.queryStringParameters.ageGroup = props.ageGroup
        }

        if (props.fitnessLevelId) {
            config.queryStringParameters.fitnessLevelId = props.fitnessLevelId
        }

        return API.get(API_STATISTICS_NAME, '/progress/internal', config);
    }

    static getProgressExport(props) {
        const config = {
            queryStringParameters: {
                viewFrom: props.viewFrom.format(DATE_FORMAT),
                viewTo: props.viewTo.format(DATE_FORMAT)
            }
        };

        if (props.organisationId) {
            config.queryStringParameters.organisationId = props.organisationId
        }

        if (props.statsType) {
            config.queryStringParameters.statsType = props.statsType
        }

        if (props.categoryId) {
            config.queryStringParameters.categoryId = props.categoryId
        }

        if (props.occupationId) {
            config.queryStringParameters.occupationId = props.occupationId
        }

        if (props.teamId) {
            config.queryStringParameters.teamId = props.teamId
        }

        if (props.locationId) {
            config.queryStringParameters.locationId = props.locationId
        }

        if (props.genderId) {
            config.queryStringParameters.genderId = props.genderId
        }

        if (props.age) {
            config.queryStringParameters.ageGroup = props.age
        }

        if (props.fitnessLevelId) {
            config.queryStringParameters.fitnessLevelId = props.fitnessLevelId
        }

        return API.get(API_STATISTICS_NAME, '/progress/export', config);
    }

    static getBestWorstSubcategories(props) {
        const config = {
            queryStringParameters: {
                dateFrom: props.viewFrom.format(DATE_FORMAT),
                dateTo: props.viewTo.format(DATE_FORMAT),
            }
        };

        if (props.organisationId) {
            config.queryStringParameters.organisationId = props.organisationId;
        }

        return API.get(API_STATISTICS_NAME, `/statistics/subcategory`, config);
    }

    static getAllCategories() {
        return API.get(API_QUESTIONS_NAME, '/category/all');
    }

    static getDailyQuestions() {
        return API.get(API_QUESTIONS_NAME, '/questions');
    }

    static saveAnswers(answers, extraAnswer) {
        const config = {
            body: {
                answers,
                extraAnswer
            }
        };
        return API.post(API_QUESTIONS_NAME, '/questions/answers', config);
    }

    static getDailyAnswers() {
        return API.get(API_QUESTIONS_NAME, '/questions/answers');
    }

    static getStatisticsExport(props) {
        const config = {
            queryStringParameters: {
                viewFrom: props.viewFrom.format(DATE_FORMAT),
                viewTo: props.viewTo.format(DATE_FORMAT),
                viewType: props.viewType,
            }
        };

        if (props.statsType) {
            config.queryStringParameters.statsType = props.statsType
        }

        if (props.categoryId) {
            config.queryStringParameters.categoryId = props.categoryId
        }

        if (props.organisationId) {
            config.queryStringParameters.organisationId = props.organisationId
        }

        if (props.occupationId) {
            config.queryStringParameters.occupationId = props.occupationId
        }

        if (props.teamId) {
            config.queryStringParameters.teamId = props.teamId
        }

        if (props.locationId) {
            config.queryStringParameters.locationId = props.locationId
        }

        if (props.genderId) {
            config.queryStringParameters.genderId = props.genderId
        }

        if (props.age) {
            config.queryStringParameters.ageGroup = props.age
        }

        if (props.fitnessLevelId) {
            config.queryStringParameters.fitnessLevelId = props.fitnessLevelId
        }

        if (props.source) {
            config.queryStringParameters.source = props.source
        }

        return API.get(API_STATISTICS_NAME, `/statistics/export`, config);
    }

    static getDetailedStatisticsExport(props) {
        const config = {
            queryStringParameters: {
                viewFrom: props.viewFrom.format(DATE_FORMAT),
                viewTo: props.viewTo.format(DATE_FORMAT),
                viewType: props.viewType,
            }
        };

        if (props.statsType) {
            config.queryStringParameters.statsType = props.statsType
        }

        if (props.categoryId) {
            config.queryStringParameters.categoryId = props.categoryId
        }

        if (props.organisationId) {
            config.queryStringParameters.organisationId = props.organisationId
        }

        if (props.occupationId) {
            config.queryStringParameters.occupationId = props.occupationId
        }

        if (props.teamId) {
            config.queryStringParameters.teamId = props.teamId
        }

        if (props.locationId) {
            config.queryStringParameters.locationId = props.locationId
        }

        if (props.genderId) {
            config.queryStringParameters.genderId = props.genderId
        }

        if (props.age) {
            config.queryStringParameters.ageGroup = props.age
        }

        if (props.fitnessLevelId) {
            config.queryStringParameters.fitnessLevelId = props.fitnessLevelId
        }

        if (props.source) {
            config.queryStringParameters.source = props.source
        }

        return API.get(API_STATISTICS_NAME, `/statistics/details/export`, config);
    }

    static getCorpCompare(props) {
        const config = {
            queryStringParameters: {
                viewFrom: props.viewFrom.format(DATE_FORMAT),
                viewTo: props.viewTo.format(DATE_FORMAT),
                organisationId: props.organisationId
            }
        };

        if (props.categoryId) {
            config.queryStringParameters.categoryId = props.categoryId
        }

        if (props.filterType) {
            config.queryStringParameters.filterType = props.filterType
        }

        if (props.filterData) {
            config.queryStringParameters.filterData = (props.filterData || []).join(',')
        }

        return API.get(API_STATISTICS_NAME, `/statistics/corp/compare`, config);
    }

    static getInternalCorpCompare(props) {
        const config = {
            queryStringParameters: {
                viewFrom: props.viewFrom.format(DATE_FORMAT),
                viewTo: props.viewTo.format(DATE_FORMAT)
            }
        };

        if (props.filterType) {
            config.queryStringParameters.filterType = props.filterType
        }

        if (props.filterData) {
            config.queryStringParameters.filterData = (props.filterData || []).join(',')
        }

        if (props.occupationId) {
            config.queryStringParameters.occupationId = props.occupationId
        }

        if (props.genderId) {
            config.queryStringParameters.genderId = props.genderId
        }

        if (props.ageGroup) {
            config.queryStringParameters.ageGroup = props.ageGroup
        }

        if (props.fitnessLevelId) {
            config.queryStringParameters.fitnessLevelId = props.fitnessLevelId
        }

        return API.get(API_STATISTICS_NAME, `/statistics/internal/compare`, config);
    }

    static getUserBaselineProgress() {
        return API.get(API_STATISTICS_NAME, `/baseline/progress`);
    }

    static getModules(props) {
        const config = {
            queryStringParameters: {
                page: props.page
            }
        };
        return API.get(API_QUESTIONS_NAME, `/module/all`, config);
    }

    static getOccupations(page) {
        const config = {
            queryStringParameters: {
                page: page
            }
        };

        return API.get(API_OCCUPATIONS_NAME, '/occupation', config);
    }

    static getAllOccupations() {
        return API.get(API_OCCUPATIONS_NAME, '/occupation/all');
    }

    static addOccupation(props) {
        const config = {
            body: {
                title: props.name,
                weights: props.weights
            }
        };

        return API.post(API_OCCUPATIONS_NAME, '/occupation', config)
    }

    static deleteOccupation(id) {
        return API.del(API_OCCUPATIONS_NAME, `/occupation/${id}`)
    }

    static getOneOccupation(id) {
        return API.get(API_OCCUPATIONS_NAME, `/occupation/${id}`)
    }

    static updateOccupation(props) {
        const config = {
            body: {
                title: props.name,
                weights: props.weights
            }
        };

        return API.patch(API_OCCUPATIONS_NAME, `/occupation/${props.id}`, config)
    }

    static getAllInsights(props) {
        const config = {
            queryStringParameters: {
                page: props.page
            }
        };

        if (props.stateFilter) {
            config.queryStringParameters.active = props.stateFilter
        }

        if (props.categoryFilter) {
            config.queryStringParameters.category = props.categoryFilter
        }

        if (props.subcategoryFilter) {
            config.queryStringParameters.subcategory = props.subcategoryFilter
        }

        if (props.organisationId) {
            config.queryStringParameters.organisationId = props.organisationId
        }

        return API.get(API_INSIGHTS_NAME, '/insight/all', config);
    }

    static getDailyInsight() {
        return API.get(API_INSIGHTS_NAME, '/insight');
    }

    static addInsight(props) {
        return API.post(API_INSIGHTS_NAME, '/insight', {body: props});
    }

    static getInsight(id) {
        return API.get(API_INSIGHTS_NAME, `/insight/${id}`);
    }

    static updateInsight(props) {
        return API.patch(API_INSIGHTS_NAME, `/insight/${props.id}`, {body: props});
    }

    static deleteInsight(id) {
        return API.del(API_INSIGHTS_NAME, `/insight/${id}`);
    }

    static getOrganisationTypes(page) {
        const config = {
            queryStringParameters: {
                page: page
            }
        };

        return API.get(API_ORGANISATIONS_NAME, `/organisation/type`, config);
    }

    static getAllOrganisationTypes() {
        return API.get(API_ORGANISATIONS_NAME, `/organisation/type/all`);
    }

    static getOrganisationType(id) {
        return API.get(API_ORGANISATIONS_NAME, `/organisation/type/${id}`);
    }

    static deleteOrganisationType(id) {
        return API.del(API_ORGANISATIONS_NAME, `/organisation/type/${id}`);
    }

    static createOrganisationType(props) {
        const config = {
            body: {
                name: props.name,
                weights: props.weights
            }
        };

        return API.post(API_ORGANISATIONS_NAME, `/organisation/type`, config);
    }

    static updateOrganisationType(props) {
        const config = {
            body: {
                name: props.name,
                weights: props.weights
            }
        };

        return API.patch(API_ORGANISATIONS_NAME, `/organisation/type/${props.id}`, config);
    }

    static getAllOrganisationSizes() {
        return API.get(API_ORGANISATIONS_NAME, `/organisation/size/all`);
    }

    static getLogEntry(id) {
        return API.get(API_ORGANISATIONS_NAME, `/organisation/log/${id}`);
    }

    static getUsersImportLogs(props) {
        const config = {
            queryStringParameters: {
                logType: 'USER_IMPORT_LOG',
                page: props.page
            }
        };

        if (props.organisationId) {
            config.queryStringParameters.organisationId = props.organisationId
        }

        return API.get(API_ORGANISATIONS_NAME, `/organisation/logs`, config);
    }

    static getUsersExportLogs(props) {
        const config = {
            queryStringParameters: {
                logType: 'ORGANISATION_USERS_EXPORT',
                page: props.page
            }
        };

        if (props.organisationId) {
            config.queryStringParameters.organisationId = props.organisationId
        }

        return API.get(API_ORGANISATIONS_NAME, `/organisation/logs`, config);
    }

    static getReportLogs(props) {
        const config = {
            queryStringParameters: {
                logType: 'ORGANISATION_STATISTICS_EXPORT',
                page: props.page
            }
        };

        if (props.organisationId) {
            config.queryStringParameters.organisationId = props.organisationId
        }

        return API.get(API_ORGANISATIONS_NAME, `/organisation/logs`, config);
    }

    static getOrganisationFeedback(organisationId) {
        const config = {};
        if (organisationId) {
            config.queryStringParameters = {
                organisationId
            }
        }
        return API.get(API_ORGANISATIONS_NAME, `/feedback`, config);
    }

    static getRandomFunFact() {
        return API.get(API_FUN_FACTS_NAME, '/fun-fact');
    }

    static createRecognitionLevel(props) {
        return API.post(API_RECOGNITIONS_NAME, `/recognition`, {body: props});
    }

    static updateRecognitionLevel(props) {
        return API.patch(API_RECOGNITIONS_NAME, `/recognition/${props.id}`, {body: props});
    }

    static getRecognitionLevel(id) {
        return API.get(API_RECOGNITIONS_NAME, `/recognition/${id}`);
    }

    static getUsersTodaysRecognitionLevel() {
        return API.get(API_RECOGNITIONS_NAME, `/recognition`);
    }

    static getRecognitionLevels(page) {
        const config = {
            queryStringParameters: {
                page: page
            }
        };
        return API.get(API_RECOGNITIONS_NAME, `/recognition/all`, config);
    }

    static deleteRecognitionLevel(id) {
        return API.del(API_RECOGNITIONS_NAME, `/recognition/${id}`);
    }

    static createNote(note) {
        const config = {
            body: {
                text: note.text,
                related_date: moment().format('YYYY-MM-DD')
            }
        };

        return API.post(API_NOTES_NAME, `/notes`, config);
    }

    static updateNote(note) {
        const config = {
            body: {
                text: note.text
            }
        };

        return API.put(API_NOTES_NAME, `/notes/${note.id}`, config);
    }

    static deleteNote(note) {
        return API.del(API_NOTES_NAME, `/notes/${note.id}`);
    }

    static getNotes(dateFrom, dateTo) {
        const config = {
            queryStringParameters: {
                dateFrom,
                dateTo
            }
        };

        return API.get(API_NOTES_NAME, `/notes`, config);
    }

    static getConsecutiveLevels() {
        return API.get(API_INFO_NAME, '/consecutive');
    }

    static getConsecutiveLevel(levelId) {
        return API.get(API_INFO_NAME, `/consecutive/${levelId}`);
    }

    static updateConsecutiveLevel(level) {
        const config = {
            body: {
                min: level.min,
                max: level.max,
                text: level.text,
                default: level.default
            }
        };

        return API.put(API_INFO_NAME, `/consecutive/${level.id}`, config);
    }

    static createConsecutiveLevel(level) {
        const config = {
            body: {
                min: level.min,
                max: level.max,
                text: level.text,
                default: level.default
            }
        };

        return API.post(API_INFO_NAME, `/consecutive`, config);
    }

    static deleteConsecutiveLevel(levelId) {
        return API.del(API_INFO_NAME, `/consecutive/${levelId}`);
    }

    static getConsecutiveAnswersCount() {
        return API.get(API_INFO_NAME, '/consecutive/user');
    }

    static getResources(props) {
        const config = {
            queryStringParameters: {
                page: props.page
            }
        };

        if (props.organisationId) {
            config.queryStringParameters.organisationId = props.organisationId;
        }

        return API.get(API_RESOURCES_NAME, `/resources`, config);
    }

    static getResource(id) {
        return API.get(API_RESOURCES_NAME, `/resource/${id}`);
    }

    static createResource(params) {
        const config = {
            body: {
                title: params.title,
                description: params.description,
                organisationId: params.organisationId,
                file: params.file,
                icon: params.icon,
                fileType: params.fileType
            }
        };
        return API.post(API_RESOURCES_NAME, `/resource`, config);
    }

    static updateResource(params) {
        const config = {
            body: {
                id: params.id,
                title: params.title,
                description: params.description,
                organisationId: params.organisationId,
                file: params.file,
                icon: params.icon,
                fileType: params.fileType
            }
        };
        return API.put(API_RESOURCES_NAME, `/resource/${params.id}`, config);
    }

    static deleteResource(id) {
        return API.del(API_RESOURCES_NAME, `/resource/${id}`);
    }

    static getTBucks() {
        return API.get(API_TBUCKS_NAME, `/tbucks`)
    }

    static redeemTBucks() {
        return API.post(API_TBUCKS_NAME, `/tbucks/redeem`)
    }

    static createTBucksInfo(params) {
        const config = {
            body: {
                text: params.text,
                organisationId: params.organisationId,
            }
        };
        return API.post(API_TBUCKS_NAME, `/tbucks/info`, config);
    }

    static updateTBucksInfo(params) {
        const config = {
            body: {
                text: params.text
            }
        };
        return API.put(API_TBUCKS_NAME, `/tbucks/info/${params.id}`, config);
    }

    static getTBucksInfos(props) {
        const config = {
            queryStringParameters: {
                page: props.page
            }
        };

        if (props.organisationId) {
            config.queryStringParameters.organisationId = props.organisationId;
        }

        return API.get(API_TBUCKS_NAME, `/tbucks/info`, config);
    }

    static getTBucksInfo(id) {
        return API.get(API_TBUCKS_NAME, `/tbucks/info/${id}`);
    }

    static getTBucksInfoForUser() {
        return API.get(API_TBUCKS_NAME, `/tbucks/info/user`);
    }

    static deleteTBucksInfo(id) {
        return API.del(API_TBUCKS_NAME, `/tbucks/info/${id}`);
    }

    static getPerformanceMessageList(page) {
        const config = {
            queryStringParameters: {
                page: page
            }
        };

        return API.get(API_PERFORMANCE_NAME, `/performance`, config);
    }

    static getPerformanceMessage(id) {
        return API.get(API_PERFORMANCE_NAME, `/performance/${id}`);
    }

    static createPerformanceMessage(params) {
        const config = {
            body: {
                text: params.text,
                positive: params.positive,
                category_id: params.category_id,
                subcategory_id: params.subcategory_id
            }
        }
        return API.post(API_PERFORMANCE_NAME, `/performance`, config);
    }

    static updatePerformanceMessage(params) {
        const config = {
            body: {
                text: params.text,
                positive: params.positive,
                category_id: params.category_id,
                subcategory_id: params.subcategory_id
            }
        }
        return API.put(API_PERFORMANCE_NAME, `/performance/${params.id}`, config);
    }

    static deletePerformanceMessage(id) {
        return API.del(API_PERFORMANCE_NAME, `/performance/${id}`);
    }

    static requestBuddy(emailAddress) {
        const config = {
            body: {
                email_address: emailAddress
            }
        };

        return API.post(API_BUDDY_NAME, `/buddy`, config);
    }

    static getBuddies() {
        return API.get(API_BUDDY_NAME, `/buddies`);
    }

    static deleteBuddy(emailAddress) {
        return API.del(API_BUDDY_NAME, `/buddy/${emailAddress}`);
    }

    static createSpeakupAdmin(params) {
        const config = {
            body: {
                email_address: params.email_address,
                organisation_id: params.organisation_id,
                location_id: params.location_id,
                password: params.password
            }
        };

        return API.post(API_SPEAKUP_USERS_NAME, `/user`, config);
    }

    static updateSpeakupAdmin(params) {
        const config = {
            body: {
                email_address: params.email_address,
                organisation_id: params.organisation_id,
                location_id: params.location_id
            }
        };

        return API.put(API_SPEAKUP_USERS_NAME, `/user/${params.id}`, config);
    }

    static listSpeakupAdmins(token) {
        const queryParams = `?token=${encodeURIComponent(token)}`;
        return API.get(API_SPEAKUP_USERS_NAME, `/users${token ? queryParams : ''}`);
    }

    static getSpeakupAdmin(userId) {
        return API.get(API_SPEAKUP_USERS_NAME, `/user/${userId}`);
    }

    static deleteSpeakupAdmin(userId) {
        return API.del(API_SPEAKUP_USERS_NAME, `/user/${userId}`);
    }

}
