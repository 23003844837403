const API_URL = "https://api.dev.tcup.dev.solidstudio.io";

export default {
    CHECKUP_URL: 'https://dev.checkup.dev.solidstudio.io',
    DEFAULT_REGION: "eu-west-2",
    mailchimp: {
        unique_id: "403f4ede736685935e5809e48",
        audience_id: "5514829c3c"
    },
    contactUs: {
        EMAIL: "hello@tcup.co.uk"
    },
    s3: {
        BUCKET: "tcup-app-uploads"
    },
    cognito: {
        USER_POOL_ID: "eu-west-2_QviTlSrDS",
        APP_CLIENT_ID: "53qe7a6mm0mqlaa7chgtr6jog7",
        IDENTITY_POOL_ID: "eu-west-2:77011df8-1d32-45f1-ac35-8f2cc79cea59",
        MFA_ENABLED: true
    },
    apis: {
        userApi: {
            URL: `${API_URL}/users-service`
        },
        userSettingsApi: {
            URL: `${API_URL}/user-settings-service`
        },
        organisationsApi: {
            URL: `${API_URL}/organisations-service`
        },
        questionsApi: {
            URL: `${API_URL}/questions-service`
        },
        statisticsApi: {
            URL: `${API_URL}/statistics-service`
        },
        occupationsApi: {
            URL: `${API_URL}/occupations-service`
        },
        insightsApi: {
            URL: `${API_URL}/insights-service`
        },
        funFactsApi: {
            URL: `${API_URL}/fun-facts-service`
        },
        recognitionsApi: {
            URL: `${API_URL}/recognitions-service`
        },
        notesApi: {
            URL: `${API_URL}/notes-service`
        },
        infoApi: {
            URL: `${API_URL}/info-service`
        },
        resourcesApi: {
            URL: `${API_URL}/resources-service`
        },
        tbucksApi: {
            URL: `${API_URL}/tbucks-service`
        },
        performanceApi: {
            URL: `${API_URL}/performance-service`
        },
        buddyApi: {
            URL: `${API_URL}/buddy-service`
        },
        speakupUsersApi: {
            URL: `${API_URL}/speakup-users-service`
        }
    }
};