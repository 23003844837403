import 'core-js';
import 'isomorphic-fetch';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import Amplify, {Auth} from "aws-amplify";
import {Provider} from "react-redux";
import {HashRouter} from "react-router-dom";
import {Route} from "react-router";
import createStore from '../src/redux/store';
import smoothscroll from 'smoothscroll-polyfill';
import App from './modules/App';
import config from "./config";

smoothscroll.polyfill();

const ONLY_UNAUTHED_API_NAMES = ['funFactsApi'];

const AUTHED_APIS = Object.keys(config.apis)
    .filter(apiKey => !ONLY_UNAUTHED_API_NAMES.includes(apiKey))
    .map(apiKey => ({
        name: apiKey,
        endpoint: config.apis[apiKey].URL,
        region: config.DEFAULT_REGION,
        custom_header: async () => ({Authorization: (await Auth.currentSession()).idToken.jwtToken})
    }));

const UNAUTHED_APIS = [
    {
        name: "userSettingsApiNoAuth",
        endpoint: config.apis.userSettingsApi.URL,
        region: config.DEFAULT_REGION,
    },
    {
        name: "userApiNoAuth",
        endpoint: config.apis.userApi.URL,
        region: config.DEFAULT_REGION
    },
    {
        name: "funFactsApi",
        endpoint: config.apis.funFactsApi.URL,
        region: config.DEFAULT_REGION
    }
];

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.DEFAULT_REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    Storage: {
        region: config.DEFAULT_REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID
    },
    API: {
        endpoints: [
            ...UNAUTHED_APIS,
            ...AUTHED_APIS
        ]
    }
});

if (!Array.prototype.flatMap) {
    const concat = (x, y) => x.concat(y);
    const flatMap = (f, xs) => xs.map(f).reduce(concat, []);

    /*eslint no-extend-native: ["off", { "exceptions": ["Object"] }]*/
    Array.prototype.flatMap = function (f) {
        return flatMap(f, this)
    };
}

ReactDOM.render(
    <Provider store={createStore()}>
        <HashRouter>
            <Route component={App}/>
        </HashRouter>
    </Provider>
    , document.getElementById('root'));