export const createAction = (type, payload) => ({ type, payload });

export const USER = {
    LOGGED_IN: 'LOGGED_IN',
    FETCH_USER_DATA_START: 'FETCH_USER_DATA_START',
    FETCH_USER_DATA_SUCCESS: 'FETCH_USER_DATA_SUCCESS',
    FETCH_USER_DATA_ERROR: 'FETCH_USER_DATA_ERROR',

    UPDATE_USER_DATA_START: 'UPDATE_USER_DATA_START',
    UPDATE_USER_DATA: 'UPDATE_USER_DATA_SUCCESS'
};

export const CATEGORIES = {
    FETCH_CATEGORIES_START: 'FETCH_CATEGORIES_START',
    FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
    FETCH_CATEGORIES_ERROR: 'FETCH_CATEGORIES_ERROR'
}

export const ADMIN_MENU = {
    TOGGLE_MENU: 'TOGGLE_MENU'
}